import React, { useState } from 'react';
import { useLoginMutation, useAddEntityMutation, useGetEntitiesByFieldQuery,  useGetPartiesQuery} from '../../services/gamebox';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";

import { Formik } from 'formik'
import TextField from '../../components/common/TextField';

export default function JoinGame() {
  const { code } = useParams();
  
  const { data: gameroom, error: cerror, isLoading: cisLoading } = useGetEntitiesByFieldQuery({ name: 'gameroom', field: 'join_code', value: code })
  const [addEntity, { isLoading: isUpdating }] = useAddEntityMutation()
  const [login, { isLoading: isLoggingIn }] = useLoginMutation()

  const navigate = useNavigate()
  
  
  const joinGame = async (vals) => {    
    const e = await addEntity({ name: 'player', body: { data: { gameroom: gameroom.data[0].id, name: vals.name} } })
    //check if any error came
    if (!e.error) {
      localStorage.setItem('user', JSON.stringify(e.data));
      navigate(`/gameroom/${code}`)
    }    
  }



  if (gameroom && gameroom.data.length === 0) {
    return (
      <div>
        Wrong join code       
      </div>
    )
  } 
  else {    
    return (
      <div className="container">
        {gameroom && <div>{gameroom.data[0].attributes.name}</div>}
        <div className="row justify-content-center">
          <Formik enableReinitialize onSubmit={joinGame} initialValues={{ name: ''}}>
            {(props) => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <Form.Group controlId="formBasicName">
                  <div className="col-xl-10 col-lg-12 col-md-9">
                    <TextField name="name" label="Choose your name:" placeholder="Enter Name" />                    
                  </div>
                  
                  <Button type="submit" >Join Game Room</Button>
                </Form.Group>
              </Form>
            )}
          </Formik>  
        </div>
      </div>
    );
  }

};