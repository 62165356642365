// @flow
import React from 'react';
import { Row, Col } from 'react-bootstrap';

// components
import PageTitle from '../../components/PageTitle';
import PricingCard from '../../components/PricingCard';

//css
import '../../assets/pricing.css';

// Pricing component
const Pricing = (): React$Element<React$FragmentType> => {
    const plans = [
        {
            id: 1,
            name: 'Individual Pack',
            icon: 'dripicons-user',
            monthlyPrice: '$3',
            yearlyPrice: '$25',
            planId: 'WORD_HUNT',
            features: ['1 user account', 
              '600 total words', 'Multiple Worlds', 'More words added every month'],
            isRecommended: false,
        },
        {
            id: 2,
            name: '30 Students Teacher Pack',
            icon: 'dripicons-briefcase',
            monthlyPrice: '$10',
            yearlyPrice: '$80',
            planId: 'WORD_HUNT_30',
            duration: 'Month',
            features: ['Up to 30 students can play',
            '600 total words', 'Multiple Worlds', 'More words added every month'],
            isRecommended: false,
        },
        {
            id: 3,
            name: '100 Students Teacher Pack',
            icon: 'dripicons-store',
            monthlyPrice: '$15',
            planId: 'WORD_HUNT_100',
            yearlyPrice: '$125',
            duration: 'Month',
            features: [
                'Up to 100 students can play',
                '600 total words', 'Multiple Worlds', 'More words added every month'],
            isRecommended: false,
        },
  ];
  
    return (
      <div style={{ backgroundColor: 'black', height: '100%' }}>
            <Row/>
            <Row className="justify-content-center">
                <Col xl={10}>
                    <div className="text-center">
                        <h1 className="mb-2" style={{color:'#cf7711', fontFamily: 'Comfortaa', paddingTop: '4rem'}}>Choose a Plan to Upgrade Lexica</h1>
                        <p className="text-muted w-50 m-auto">
                            
                        </p>
                    </div>

                    {/* pricing cards */}
                    <PricingCard plans={plans} containerClass={'mt-sm-5 mt-3 mb-3'} />
                </Col>
            </Row>
        </div>
    );
};

export default Pricing;
