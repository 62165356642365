import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SweetAlert2 from 'react-sweetalert2';
import Image from 'react-bootstrap/Image';
import spinner from '../assets/images/spinner.gif';
import { showAlert } from '../redux/actions';
import { useUpdateEntityMutation } from '../services/gamebox';

export default function Alert(props) {

  const { message } = useSelector((state) => ({
    message: state.theme.Game.message,
  }));
  const dispatch = useDispatch()

  const [updateEntity] = useUpdateEntityMutation()
  return (
    <SweetAlert2 {...message}
      onResolve={result => {
        console.log(result);
        if(result.isConfirmed) {          
          updateEntity({ name: 'challenge', id: message.msgBody.id, body: { data: { status: 'ACCEPTED' } } })          
        } else {
          updateEntity({ name: 'challenge', id: message.msgBody.id, body: { data: { status: 'REJECTED' } } })                    
        }

        dispatch(showAlert({
          show:false,               
        }));   
      }}     
    >
      {message.message}
      {message.showSpinner && <div><Image src={spinner} /></div>}
    </SweetAlert2>
  );
};
