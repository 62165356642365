import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextField from '../../components/common/TextField';

import { useAddEntityMutation } from '../../services/gamebox';
import { useNavigate } from 'react-router-dom'


const GameSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),  
});

export default function NewGameRoom() {
  const [joinCode, setJoinCode] = useState('');
  

  const navigate = useNavigate()
  const [
    addEntity, 
    { isLoading: isUpdating, error }
  ] = useAddEntityMutation()
  

  let newGame = async (values) => {       
    let response = await addEntity({name: 'gameroom', body:{data: values}})         
    if(!response.error) {
      setJoinCode(response.data.join_code)
    }
  }
  if(joinCode==='') {
    return (      
      <NewGameForm onSubmit={newGame}/>      
    );
  } else {
    return (
      <div className="container">
        <div className="row">
          Your Join code is : {joinCode}
        </div>
        <div className="row">
          <Button onClick={() => {navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}/joingame/${joinCode}`)}}>
            Copy join link
          </Button>
        </div>
        <div className="row">
          <Link to={`/joingame/${joinCode}`}>
            <Button>
              Join the game room
            </Button>
          </Link>
          <Link to={`/gamerooms`}>
            <Button type="secondary">
              Back to game rooms
            </Button>
          </Link>
        </div>
      </div>
    )
  }
};

function NewGameForm({onSubmit}) {
  return (
    <div className="container">
      <Formik enableReinitialize validationSchema={GameSchema} onSubmit={onSubmit} initialValues={{ name: ''}}>
        {(props) => (
          <Form noValidate onSubmit={props.handleSubmit}>
            <Form.Group controlId="formBasicName">
              
              <TextField name="name" label="Game Room Name" placeholder="Name your Game Room" />                           
              
              <Button variant="primary" type="submit">
                Create Game Room
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>                 
    </div>
  );
}

