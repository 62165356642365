import React from 'react';

import {useGetMessagesQuery } from '../services/gamebox';


export default function Messages(props) {
  const { gameroomId} = props  
  const { data: messages } = useGetMessagesQuery(gameroomId)

  return (
    <>     
    
    </>
  );
};
