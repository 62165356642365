
import React from 'react';
import { useGetEntitiesByFieldQuery, useAddEntityMutation } from '../../services/gamebox';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

export default function Challenge() {
  const gameId = useParams('id')
  const user = JSON.parse(localStorage.getItem('user'));
  const gameroomCode = user.room;

  const { data: players } = useGetEntitiesByFieldQuery({ name: 'player', field: 'gameroom', value: gameroomCode, relation: 'join_code', populate: '*' })
  //get game room using gameroom join code
  const { data: gameroom } = useGetEntitiesByFieldQuery({ name: 'gameroom', field: 'join_code', value: gameroomCode })


  
  const [addChallege] = useAddEntityMutation()

  const createChallenge = (playerId) => {
    addChallege({ name: 'challenge', body: { data: { gameroom: gameroom.data[0].id, to_player: playerId, from_player: user.user.id, game:gameId, status:'NEW' } } })
  }

  return (
    <div className="container-fluid pt-2">
      <div className="row">
        {gameroom && players && players.data.map(player =>
          <div className="col-xxl-4 col-lg-4 col-md-4 py-2" key={player.id}>
            <div className="card shadow-sm h-100 ">
              <div className="card-body">                                
                  <Button onClick={()=>createChallenge(player.id)}>{player.attributes.name}</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

}