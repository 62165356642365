import React from 'react';
import { useGetEntitiesQuery, useAddEntityMutation } from '../../services/gamebox';
import { Link } from 'react-router-dom';
import TextField from '../../components/common/TextField';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';

export default function GameRoom() {
  const JoinSchema = Yup.object().shape({
    join_code: Yup.string()
      .required('Required'),
  });

  //get gameroom from localstorage
  const gameroom = JSON.parse(localStorage.getItem('user')).gameoom;
  //load games
  const { data: games} = useGetEntitiesQuery({name:'game', populate: '*'})

  
  return (

    <div className="container-fluid pt-2">
        <div className="row">

          {games && games.data.map(game =>
          
            <div className="col-xxl-4 col-lg-4 col-md-4 py-2" key={game.id}>
              <div className="card shadow-sm h-100 ">
                <div className="card-body">                           
                  {game.attributes.name}
                  {game.attributes.images.data && <img src={`http://localhost:1337${game.attributes.images.data[0].attributes.formats.thumbnail.url}`} />}
                  {(game.attributes.game_type === 'TWO_PLAYER') &&  
                    <Link to={`${game.attributes.url}`}><Button>Challenge a Friend</Button></Link>
                  }
                  

                </div>
              </div>
            </div>      
          )} 
        </div>
    </div>        
  );

};