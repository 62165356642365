import React, { useState }  from 'react';
import { useGetEntitiesQuery, useUpdateEntityMutation } from '../../services/gamebox';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useLocalStorage from '../../hooks/useLocalStorage';



export default function Student() {
  const { data: students } = useGetEntitiesQuery({ name: 'student', populate: '*' });
  const [updateEntity, { isLoading: isUpdating }] = useUpdateEntityMutation();
  const [user, setUser] = useLocalStorage("user", "");
  const [studentData, setStudentData] = useState([]);

  const handleNameChange = (event, studentId) => {
    const updatedStudents = studentData.map(student => {
      if (student.id === studentId) {
        return { ...student, attributes: { ...student.attributes, name: event.target.value } };
      }
      return student;
    });
    setStudentData(updatedStudents);
  };

  const handleNameBlur = (event, studentId) => {    
    updateEntity({ name: 'student', id: studentId, body: {data: {name: event.target.value }}  });
  };

  const handleUpdate = () => {
    console.log('Updated student data:', studentData);
    // Call your update function here with the updated studentData
  };

  React.useEffect(() => {
    if (students) {
      setStudentData(students.data);
    }
  }, [students]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <p></p>
          <h3>Students may enter these codes to gain access to the premium version.</h3>
          <h3>Codes can be personalized by assigning them to specific student names.</h3>
        <div className="col-xl-10 col-lg-12 col-md-9">

          <table className="mb-0 table table-sm">
            <thead>
              <tr><th>Name</th><th>Game Code</th></tr>
            </thead>
            <tbody>
              {studentData.map(student => (
                <tr key={student.id}>
                  <td>
                    <input
                      type="text"
                      value={student.attributes.name}
                      onChange={event => handleNameChange(event, student.id)}
                      onBlur={event => handleNameBlur(event, student.id)}
                    />
                  </td>
                  <td>{student.attributes.join_code}</td>
                </tr>
              ))}
            </tbody>
          </table>         
        </div>
      </div>
    </div>
  );
};
