import React from 'react';
import { useGetEntitiesQuery, useAddEntityMutation } from '../../services/gamebox';
import { Link } from 'react-router-dom';
import TextField from '../../components/common/TextField';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';

export default function GameRooms() {
  const JoinSchema = Yup.object().shape({
    join_code: Yup.string()
      .required('Required'),
  });

  const { data, error, isLoading } = useGetEntitiesQuery({name:'gameroom'})
  const [addEntity, { isLoading: isUpdating }] = useAddEntityMutation()
  const navigate = useNavigate()
  const joinGame = (values) => {
    navigate(`/joingame/${values.join_code}`)
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9 tableFixHead">
          <table className="mb-0 table table-sm ">
            <thead>
              <tr><th>Game Room</th><th>Actions</th></tr>
            </thead>
            <tbody>
              {data && data.data.map(room =>
                <tr key={room.id}>
                  <td>{room.attributes.name}</td>
                  <td><Link to={`/gamerooms/${room.attributes.join_code}`}><Button>View Room</Button></Link> </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <Link to="/gamerooms/new" ><Button>Create New Game Room</Button></Link>
      </div>
    </div>
  );

};