// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Alert, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegisterMutation, useAddEntityMutation } from '../../services/gamebox';
import { getSession } from '../../redux/actions';

// components
import { VerticalForm, FormInput } from '../../components/';

import AccountLayout from './AccountLayout';

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-muted">
          
        </p>
      </Col>
    </Row>
  );
};

const PaymentSuccess = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [
    authRegister,
    { isLoading, error }
  ] = useRegisterMutation()

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [addEntity, { isLoading: isUpdating, err }] = useAddEntityMutation()

  const { customer } = useSelector((state) => ({
    customer: state.theme.Stripe.customer,
  }));

  useEffect(() => {    
    dispatch(getSession(searchParams.get('sessionId'), navigate))
  },[])
  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t('Please enter Password')),
    })
  );

  /*
   * handle form submission
   */
  const onSubmit = async (values) => {
    values.username = values.email = customer.email
    values.name = customer.name
    let response = await authRegister(values)
    if (!response.error) {
      localStorage.setItem('user', JSON.stringify(response.data));
      let resp = await addEntity({name: 'customer', body: { data: {sessionId: searchParams.get('sessionId'), name: customer.name }}})
      if (!resp.error) {
        navigate(`/subscriptions`)
      }      
    }
  };

  return (
    <>      
      <AccountLayout bottomLinks={<BottomLink />}>
        <div className="text-center w-75 m-auto">
          <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('Payment successful')}</h4>
          <p className="text-muted mb-4">
            {t("Please create an account to continue")}
          </p>
        </div>

        {error && (
          <Alert variant="danger" className="my-2">
            {error.data.error.message}
          </Alert>
        )}

        <VerticalForm onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{}}>
          <Form.Group>
            <Form.Label >Name</Form.Label>: <Form.Label >{customer.name}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label >Email</Form.Label>: <Form.Label >{customer.email}</Form.Label>
          </Form.Group>
          
          
          <FormInput
            label={t('Password')}
            type="password"
            name="password"
            placeholder={t('Enter your password')}
            containerClass={'mb-3'}
          />
        

          <div className="mb-3 mb-0 text-center">
            <Button variant="primary" type="submit" disabled={isLoading}>
              {t('Sign Up')}
            </Button>
          </div>
        </VerticalForm>
      </AccountLayout>
    </>
  );
};

export default PaymentSuccess;
