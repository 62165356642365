import React from 'react';
import { useGetEntitiesQuery } from '../../services/gamebox';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import useLocalStorage from '../../hooks/useLocalStorage';
import lexicaImg from '../../assets/images/lexicapremium.png';


export default function Subscriptions() {
    
  const { data: subs} = useGetEntitiesQuery({name:'subscription', populate: '*', })
  const [user, setUser] = useLocalStorage("user", "");

  

  return (    
    <div className="container-fluid pt-2">
      <h2>My Subscriptions</h2>
        <div className="row">
          {subs && subs.data.map(sub =>          
            <div className="col-xxl-4 col-lg-4 col-md-4 py-2" key={sub.id}>
              <div className="card shadow-sm h-100 ">
                <div className="card-body">                           

                  <div><a href={`${process.env.REACT_APP_WORD_HUNT_URL}?i=${user.jwt}`}><img width="100%" src={lexicaImg} className="img-fluid" alt="Lexica Premium" /></a></div>
                  <div>
                    <a href={`${process.env.REACT_APP_WORD_HUNT_URL}?i=${user.jwt}`}><Button className="m-1">Play Premium Version</Button></a> 

                    {sub.attributes.product != 'WORD_HUNT' && <Link to='/students'><Button>Student Codes</Button></Link>}
                  </div>                  
                </div>
              </div>
            </div>      
          )} 
        </div>
    </div>        
  );

};